@font-face {
  font-family: "SeagoeUIBold";
  src: local("SeagoeUIBold"), url(./fonts/SegoeUIBold.ttf) format("truetype");
}

@font-face {
  font-family: "SeagoeUI";
  src: local("SeagoeUI"), url(./fonts/SegoeUI.ttf) format("truetype");
}

@font-face {
  font-family: "SeagoeUIItalic";
  src: local("SeagoeUIItalic"),
    url(./fonts/SegoeUIItalic.ttf) format("truetype");
}

* {
  font-family: "SeagoeUI";
}

body {
  background-color: rgb(244, 246, 248);
}

.searchbar {
  margin: 10px;
  width: 100%;
  height: 50px;
  border: none;
  border-bottom: 2px solid rgba(36, 36, 36, 0.1);
  background-color: transparent;
}

.searchbar[type="text"] {
  font-size: 1rem;
  padding: 0px 15px;
}

.new-asset-btn {
  display: inline-flex;
  justify-content: space-between;
  cursor: pointer;
  min-width: 80px;
  border-radius: 8px;
  color: rgb(255, 255, 255);
  background-color: rgb(33, 43, 54, 0.9);
  margin-right: 10px;
  padding: 10px 15px;
  width: auto;
}

.list-icon-order {
  color: white;
  border-radius: 8px;
  cursor: pointer;
}

.new-asset-btn:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.save-asset-btn {
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 8px;
  color: rgb(255, 255, 255);
  background-color: rgb(33, 43, 54, 0.9);
  padding: 10px 15px;
}

.save-asset-btn:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.new-asset-btn-text {
  line-height: normal;
  font-size: 0.8rem;
  margin-bottom: 2px;
}

.custom-table-container {
  width: 100%;
  overflow-x: auto;
  box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px,
    rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
  border-radius: 16px;
}

.search-and-add {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.header-default {
  font-size: 1.25rem;
  font-family: "SeagoeUIBold";
  color: rgba(35, 43, 53);
  margin-bottom: 10px;
}

.header-active {
  font-size: 0.875rem;
  letter-spacing: 0.5px;
  color: rgba(35, 43, 53, 0.5);
}

.header-inactive {
  font-size: 0.875rem;
  letter-spacing: 0.5px;
  color: #000;
  cursor: pointer;
}

.route {
  padding: 2rem;
}

.page-wrapper {
  margin-left: 240px;
  padding-top: 30px;
  position: relative;
  transition: all 0.4s ease;
  background-color: rgb(244, 246, 248);
}

#dropdown-flag {
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

#dropdown-flag:hover {
  transform: scale(1.1);
}

#fullscreen-icon {
  font-size: 1.5rem;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

#fullscreen-icon:hover {
  transform: scale(1.1);
}

#fullscreen-btn {
  display: flex;
  align-items: center;
  border: none;
  padding: 0;
  background-color: transparent;
}

.lang-menu {
  position: absolute;
  display: flex;
  color: rgb(33, 43, 54);
  list-style: none;
  opacity: 1;
  top: 35px;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.745);
  outline: 0px;
  min-height: 16px;
  min-width: 16px;
  max-height: calc(100% - 32px);
  max-width: calc(100% - 32px);
  box-shadow: rgba(145, 158, 171, 0.24) 0px 0px 2px 0px,
    rgba(145, 158, 171, 0.24) -20px 20px 40px -4px;
  backdrop-filter: blur(20px);
  padding: 4px;
  width: 160px;
  margin-left: 12px;
  border-radius: 10px;
  transition: opacity 279ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 186ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.lang-menu.hidden {
  opacity: 0;
  pointer-events: none;
}

.lang-menu li {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
  padding: 2px;
  font-size: 0.875rem;
  border-radius: 10px;
  box-sizing: border-box;
}

.lang-menu li:hover {
  background-color: rgba(145, 158, 171, 0.16);
}

.lang-menu li img {
  margin-right: 20px;
}

.flags-menu {
  display: inline-block;
}

.img-uk-flag {
  border-radius: 8px;
}

.avatar-sidebar {
  position: relative;
}

.overview-sidebar {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-block: 50px;
}

.name-sidebar {
  font-size: 0.9rem;
  font-family: "SeagoeUIBold";
  letter-spacing: 0.3px;
  margin-top: 10px;
}

.email-sidebar {
  font-size: 0.8rem;
  color: rgba(35, 43, 53, 0.6);
  letter-spacing: 0.5px;
  margin-block: 5px;
}

.button-sidebar {
  background-color: rgb(33, 43, 54, 0.9);
  height: 30px;
  width: 100px;
  border-radius: 8px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.role-sidebar {
  background-color: rgb(33, 43, 54, 0.9);
  padding: 3px 8px;
  color: #fff;
  font-family: "SeagoeUIBold";
  font-size: 0.8rem;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 8px;
  white-space: nowrap;
}

.avatar-top-right {
  position: absolute;
  top: -20px;
  z-index: 1;
  left: 35px;
}

.button-sidebar:hover {
  background-color: rgb(33, 43, 54, 1);
  transition: 0.5s;
}

.mini-sidebar .menu {
  width: 66px !important;
}
.mini-sidebar .menu:hover {
  width: 240px !important;
}

.slide-nav .sidebar {
  margin-left: 0;
}

.sidebar-overlay {
  background-color: rgba(0, 0, 0, 0.6);
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 60px;
  width: 100%;
  z-index: 1000;
}

.sidebar {
  background-color: rgb(244, 246, 248);
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);*/
  bottom: 0;
  left: 0;
  margin-top: 0;
  position: fixed;
  top: 60px;
  transition: all 0.2s ease-in-out 0s;
  width: 240px;
  z-index: 1001;
  overflow: auto;
}
.sidebar.opened {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.sidebar-inner {
  height: 100%;
  min-height: 100%;
  transition: all 0.2s ease-in-out 0s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.sidebar-menu {
  padding: 15px;
}
.sidebar-menu ul {
  font-size: 15px;
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: relative;
}

.sidebar-dropdown {
  transition: all 0.5s ease-in-out;
  opacity: 0;
  overflow: hidden;
  height: 0;
}

.sidebar-dropdown.show {
  transition: all 0.5s ease-in-out;
  opacity: 1;
  height: auto;
}

.sidebar-menu ul li {
  cursor: pointer;
}

sidebar-menu li a {
  color: rgba(35, 43, 53);
  display: block;
  font-size: 15px;
  height: auto;
  padding: 0 20px;
}
.sidebar-menu li a:hover {
  font-family: "SeagoeUIBold";
}
.sidebar-menu > ul > li > a:hover {
  background-color: rgba(35, 43, 53, 0.1);
  color: rgba(35, 43, 53);
}
.sidebar-menu > ul > li.active > a:hover {
  background-color: rgba(35, 43, 53, 0.1);
  color: rgba(35, 43, 53);
}
.sidebar-menu li.active a {
  background-color: rgba(35, 43, 53, 0.1);
  color: rgba(35, 43, 53);
}

.sidebar-menu li.menu-title a {
  color: #ff9b44;
  display: inline-block;
  margin-left: auto;
  padding: 0;
}
.sidebar-menu li.menu-title a.btn {
  color: #fff;
  display: block;
  float: none;
  font-size: 15px;
  margin-bottom: 15px;
  padding: 10px 15px;
}
.sidebar-menu ul ul a.active {
  color: #20e3ff;
  text-decoration: underline;
}

.sidebar .sidebar-menu > ul > li > a span {
  transition: all 0.2s ease-in-out 0s;
  display: inline-block;
  margin-left: 10px;
  white-space: nowrap;
}
.sidebar .sidebar-menu > ul > li > a span.chat-user {
  margin-left: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sidebar .sidebar-menu > ul > li > a span.badge {
  margin-left: auto;
}
.sidebar-menu ul ul a {
  display: block;
  font-size: 15px;
  padding: 7px 10px 7px 45px;
  position: relative;
}
/* .sidebar-menu ul ul {
  display: none;
} */
.sidebar-menu ul ul ul a {
  padding-left: 65px;
}
.sidebar-menu ul ul ul ul a {
  padding-left: 85px;
}
.sidebar-menu > ul > li {
  margin-bottom: 10px;
  position: relative;
}
.sidebar-menu > ul > li:last-child {
  margin-bottom: 0;
}
.sidebar-menu .menu-arrow {
  -webkit-transition: -webkit-transform 0.15s;
  -o-transition: -o-transform 0.15s;
  transition: transform 0.15s;
  position: absolute;
  right: 15px;
  display: inline-block;
  text-rendering: auto;
  line-height: 40px;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  line-height: 18px;
  top: 11px;
}
.sidebar-menu .menu-arrow:before {
  content: "\f105";
}
.sidebar-menu li a.subdrop .menu-arrow {
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.sidebar-menu ul ul a .menu-arrow {
  top: 10px;
}

input {
  outline: none;
}

.sidebar-menu > ul > li > a {
  align-items: center;
  border-radius: 3px;
  display: flex;
  justify-content: flex-start;
  padding: 8px 15px;
  position: relative;
  transition: all 0.2s ease-in-out 0s;
  color: rgba(35, 43, 53, 0.8);
}
.sidebar-menu ul li a i {
  display: inline-block;
  font-size: 24px;
  line-height: 24px;
  text-align: left;
  vertical-align: middle;
  width: 20px;
  transition: all 0.2s ease-in-out 0s;
}
.sidebar-menu ul li.menu-title a i {
  font-size: 16px !important;
  margin-right: 0;
  text-align: right;
  width: auto;
}
.sidebar-menu li a > .badge {
  color: #fff;
}

.sidebar.active .menu {
  width: 70px !important;
}

.mini-sidebar.expand-menu .sidebar .menu {
  width: 240px !important;
}

.mini-sidebar.expand-menu i {
  display: inline-block !important;
}
.mini-sidebar .downarrow i {
  display: none;
}

.mini-sidebar.expand-menu i {
  display: inline-block !important;
}
.mini-sidebar .downarrow i {
  display: none;
}

.chevron {
  position: absolute;
  right: 1vw;
  cursor: pointer;
}

.main-wrapper {
  background: #fff;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  background-color: rgb(244, 246, 248);
}

.error-page .main-wrapper {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  justify-content: center;
  width: 100%;
  min-height: unset;
}

.header-default {
  font-size: 1.25rem;
  font-family: "SeagoeUIBold";
  color: rgba(35, 43, 53);
  margin-bottom: 10px;
}

.header-active {
  font-size: 0.875rem;
  letter-spacing: 0.5px;
  font-family: "SeagoeUI";
  color: rgba(35, 43, 53, 0.5);
}

.header-inactive {
  font-size: 0.875rem;
  letter-spacing: 0.5px;
  font-family: "SeagoeUI";
  color: #000;
  cursor: pointer;
}

.header-inactive:hover {
  text-decoration-line: underline;
}
.header-right {
  display: flex;
  align-items: center;
  gap: 1vw;
}

.header {
  background: rgb(244, 246, 248);
  /* border-bottom: 1px solid #f0f0f0; */
  left: 0;
  position: fixed;
  right: 0;
  justify-content: space-between;
  top: 0;
  z-index: 1001;
  height: 70px;
}

.header .header-left {
  float: left;
  height: 40px;
  position: relative;
  text-align: center;
  z-index: 1;
  transition: all 0.2s ease-in-out;
}
.header .header-left .logo {
  display: inline-block;
  line-height: 60px;
}
.header .header-left .logo img {
  max-height: 60px;
  width: auto;
}
.header-left .logo.logo-small {
  display: none;
}
.header .dropdown-menu > li > a {
  position: relative;
}
.header .dropdown-toggle:after {
  display: none;
}
.header .has-arrow .dropdown-toggle:after {
  border-top: 0;
  border-left: 0;
  border-bottom: 2px solid #333;
  border-right: 2px solid #333;
  content: "";
  height: 8px;
  display: inline-block;
  pointer-events: none;
  -webkit-transform-origin: 66% 66%;
  -ms-transform-origin: 66% 66%;
  transform-origin: 66% 66%;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  width: 8px;
  vertical-align: 2px;
}
.header .has-arrow .dropdown-toggle[aria-expanded="true"]:after {
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
.header .dropdown-menu > li > a:focus,
.header .dropdown-menu > li > a:hover {
  background-color: rgb(73, 83, 159);
  color: #fff;
}
.header .dropdown-menu > li > a:focus i,
.header .dropdown-menu > li > a:hover i {
  color: #fff;
}
.header .dropdown-menu > li > a {
  padding: 10px 18px;
}
.header .dropdown-menu > li > a i {
  color: rgb(73, 83, 159);
  margin-right: 10px;
  text-align: center;
  width: 18px;
}
.header .user-menu .dropdown-menu > li > a i {
  color: rgb(73, 83, 159);
  font-size: 16px;
  margin-right: 10px;
  min-width: 18px;
  text-align: center;
}
.header .user-menu .dropdown-menu > li > a:focus i,
.header .user-menu .dropdown-menu > li > a:hover i {
  color: #fff;
}

.header-right-left-side {
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1vw;
}

.job-role-text-header {
  color: white;
  background-color: rgb(33, 43, 54, 0.9);
  font-size: 0.8rem;
  padding: 3px 8px;
  border-radius: 8px;
  font-family: "SeagoeUIBold";
  cursor: pointer;
}

.avatar-popover {
  transition: transform 0.3s ease-in-out;
}

.avatar-popover:hover {
  transform: scale(1.1);
}

.users-stats-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5vw;
}

.users-stats-card {
  width: 27%;
  height: 7vw;
  padding: 2vw;
  background-color: white;
  border-radius: 20px;
  display: flex;
  box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px,
    rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
  justify-content: space-between;
  align-items: center;
  min-height: 100px;
}

.card-info {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-info-title {
  font-family: "SeagoeUIBold";
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.57143;
}

.card-info-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
}

.card-info-office {
  font-size: 1.1vw;
  font-family: "SeagoeUIBold";
  line-height: 1.5;
}

.card-icon {
  width: 80px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.welcome-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 300px;
}

.welcome-row-kdg {
  display: flex;
  justify-content: center;
  width: 32%;
  height: 100%;
  border-radius: 16px;
  position: relative;
  cursor: pointer;
}

.welcome-row-kdg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 10;
  border-radius: 16px;
}

.welcome-row-kdg:hover::before {
  opacity: 1;
}

.welcome-row-kdg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
}

.welcome-back-card {
  display: flex;
  width: 66%;
  height: 100%;
  color: rgb(0, 75, 80);
  position: relative;
  border-radius: 16px;
  align-items: center;
  background: linear-gradient(
      135deg,
      rgba(85, 122, 183, 0.1),
      rgba(68, 83, 107, 0.6)
    )
    rgb(255, 255, 255);
}

.welcome-left {
  text-align: left;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  display: flex;
  flex-grow: 1;
  padding: 40px;
}

.welcome-right {
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 360px;
}

.welcome-profile-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 0px;
  cursor: pointer;
  background-color: rgb(68, 83, 107);
  color: white;
  border-radius: 8px;
  padding: 6px 12px;
  font-weight: 600;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.welcome-profile-btn-text {
  line-height: normal;
  font-family: "SeagoeUIBold";
  font-size: 0.9rem;
  margin-bottom: 2px;
}

.girl-photo {
  width: 300px;
  height: 100px;
}

.welcome-img-container {
  position: absolute;
  display: flex;
  background-color: rgba(22, 28, 36, 0.48);
  width: 100%;
  height: 100%;
  border-radius: 16px;
}

.img-container-text {
  align-self: flex-end;
  padding: 16px;
}

.welcome-img-title {
  color: rgb(91, 228, 155);
  font-family: "SeagoeUIBold";
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5;
}

.welcome-img-subtitle {
  color: white;
  font-family: "SeagoeUIBold";
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.5;
}

.welcome-row-kdg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
}

.page-wrapper > .content {
  padding: 0.875rem 1.875rem 0;
}

.url-cell {
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.map-container {
  margin-top: 30px;
  border-radius: 16px;
  background-color: white;
  padding: 20px;
  padding-inline: 50px;
  box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px,
    rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
}

.leaflet-container {
  border-radius: 16px;
}
/* map end */

.form-position {
  display: flex;
  flex-wrap: wrap;
  column-gap: 1%;
  row-gap: 30px;
  width: 100%;
}

.submit-form {
  background-color: black;
  font-family: "SeagoeUIBold";
  color: white;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  margin-top: 30px;
  min-width: 64px;
  padding: 6px 12px;
  font-size: 0.875rem;
  line-height: 1.71429;
  align-self: flex-end;
}

.submit-form:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.add-video-container {
  display: flex;
  padding: 50px;
  flex-direction: column;
  width: 40%;
  gap: 20px;
}

.left-container {
  background-color: #ffffff;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}

.right-container {
  background-color: rgba(31, 41, 55, 1);
  color: white;
  opacity: 0.95;
  display: flex;
  flex-direction: column;
  row-gap: 1.5em;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}

.add-video-title {
  font-size: 2.3rem;
  font-family: "SeagoeUIBold";
  margin: 0;
  margin-bottom: 15px;
}

.left {
  align-self: flex-start;
}

.upload-area {
  cursor: pointer;
  transition: all 0.3s ease;
}

.upload-area:hover {
  border-color: #9ca3af;
  background-color: #2d3748;
  transform: scale(1.02);
}
.select-file-button {
  background-color: #2563eb;
  color: #ffffff;
  border: none;
  border-radius: 10px;
  padding: 15px 30px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.select-file-button:hover {
  background-color: #1e3a8a;
  transform: translateY(-2px);
}

.video-preview {
  padding: 15px;
  background: rgb(244, 246, 248, 0.02);
  border-radius: 16px;
}

.submit-button {
  width: 100%;
  padding: 16px;
  font-size: 1.2rem;
  color: #ffffff;
  background-color: #292929;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.15);
}

.submit-button:hover:not(:disabled) {
  background-color: #1e3a8a;
  transform: translateY(-2px);
}

.submit-button:disabled {
  background-color: #6b7280;
  cursor: not-allowed;
  opacity: 0.6;
}

.third-row {
  display: flex;
  justify-content: center;
  gap: 1vw;
}

/*start update user info */
.form-container {
  box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px,
    rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
  border-radius: 16px;
  padding: 24px;
  color: rgb(33, 43, 54);
  margin-bottom: 15px;
}

.update-password-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px,
    rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
  border-radius: 16px;
  padding: 24px;
  color: rgb(33, 43, 54);
  margin-bottom: 60px;
}

.toggle-pass-btn {
  margin-bottom: 15px;
  font-family: "SeagoeUIBold";
  background-color: transparent;
  height: 40px;
  color: black;
  border: none;
  cursor: pointer;
  margin-top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* end update user info */

.add-video-button {
  display: flex;
  align-items: center;
  background-color: #000;
  justify-content: center;
  border: 0;
  width: 100%;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  font-size: 1em;
  transition: background-color 0.3s ease;
}

.add-video-button:hover {
  background-color: #1e3a8a;
}

.remove-video-button {
  display: flex;
  align-items: center;
  background-color: #af0000;
  justify-content: center;
  border: 0;
  width: 33%;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  font-size: 1em;
  transition: background-color 0.3s ease;
}

.remove-video-button:hover {
  background-color: #ff0000;
}

.card-container {
  display: flex;
  align-items: center;
  margin-top: 1.5vw;
  gap: 2vw;
}

.card {
  width: 47%;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.upper-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.7vw;
  border-bottom: 1px solid #ddd;
  padding-top: 1vw;
}

.title {
  margin-top: 1vw;
  margin-left: 1.9vw;
  margin-bottom: 1vw;
  font-size: 20px;
  font-family: "SeagoeUI";
}

.icon {
  margin-top: 1vw;
  margin-bottom: 1vw;
  width: 35px;
  margin-right: 1.9vw;
}

.lower-section {
  padding: 16px;
  background-color: white;
  padding-top: 6vw;
  position: relative;
}

.total {
  font-size: 2.6rem;
  margin-left: 1vw;
  position: absolute;
  top: 1vw;
}

/* start partner logo */
.upload-container {
  width: 60%;
  max-height: 600px;
  /* height: 50%; */
  box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px,
    rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
  padding: 40px 24px 40px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.input-circle {
  width: 288px;
  height: 288px;
  cursor: pointer;
  border: 1px dashed rgba(145, 158, 171, 0.2);
  border-radius: 8px;
  padding: 8px;
}

.inner-circle {
  position: relative;
  width: 100%;
  height: 100%;
  gap: 8px;
  background-color: rgba(145, 158, 171, 0.08);
  border-radius: 8px;
  color: rgb(145, 158, 171);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &:hover {
    .add-photo-overlay {
      opacity: 1;
    }
  }
}

.hover-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 50%;
  row-gap: 10px;
  color: lightgray;
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
}

.inner-circle:hover .hover-overlay {
  opacity: 1;
}

.inner-circle-img-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.inner-circle-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
}

.add-photo-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.623);
  color: lightgray;
  flex-direction: column;
  row-gap: 10px;
  border-radius: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.upload-text {
  line-height: 1.5;
  font-size: 0.75rem;
  font-weight: 400;
}

.add-photo-overlay svg {
  width: 32px;
  height: 32px;
  color: white;
}

.hover-overlay svg {
  color: white;
}

.upload-text {
  line-height: 1.5;
  font-size: 0.75rem;
  font-weight: 400;
}

.upload-text-2 {
  text-align: center;
  color: rgb(145, 158, 171);
  line-height: 1.5;
  font-size: 0.75rem;
  font-weight: 400;
  margin: 24px auto 0px;
  display: block;
}

.input-field {
  display: none;
}
/* end partner logo */

/* strat dashboard modify */
.total {
  font-size: 4rem;
  font-weight: bold;
  font-family: "SeagoeUIBold";
  color: white;
  margin-left: 2vw;
}

.card-info-title-total {
  font-family: "SeagoeUIBold";
  font-size: 1.1rem;
  font-weight: bold;
  margin-right: -14vw;
  margin-top: 2.5vw;
  color: white;
}

.card-info-title-recent {
  font-family: "SeagoeUIBold";
  font-size: 1.1rem;
  font-weight: bold;
  margin-right: -2.5vw;
  margin-top: 2.5vw;
  color: white;
}

#location {
  background-color: #91d18b;
  width: 44%;
}

#video {
  background-color: #e11d74;
  width: 44%;
}

#device {
  background-color: #440047;
  width: 44%;
}
/* dashboard end modify */

.loading-status {
  background-color: orange;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 0.2vw;
  border-radius: 1.04vw;
  font-family: "SeagoeUI";
  letter-spacing: 0.052vw;
  cursor: pointer;
}

.draft-status {
  background-color: #8b0000;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 0.2vw;
  border-radius: 1.04vw;
  font-family: "SeagoeUI";
  letter-spacing: 0.052vw;
  cursor: pointer;
}

.published-status {
  /* background-color: green; */
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 0.2vw;
  border-radius: 1.04vw;
  font-family: "SeagoeUI";
  letter-spacing: 0.052vw;
  cursor: pointer;
}

@keyframes popUpEffect {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

input[type="file"]::file-selector-button {
  border-radius: 4px;
  padding: 0 16px;
  height: 40px;
  cursor: pointer;
  background-color: rgb(0, 0, 0);
  color: white;
  border: 1px solid rgba(0, 0, 0, 0.16);
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
  margin-right: 16px;
  transition: background-color 200ms;
}

/* file upload button hover state */
input[type="file"]::file-selector-button:hover {
  background-color: #2b2b2b;
}

/* file upload button active state */
input[type="file"]::file-selector-button:active {
  background-color: #2b2b2b;
}

.quantum-button {
  --primary: #1a1a1a;
  --accent: #f8f8f8;
  --duration: 1s;
  --orb-size: 14px;

  position: relative;
  padding: 16px 35px;
  background-color: var(--primary);
  border: 1.5px solid rgba(58, 6, 6, 0.1);
  border-radius: 30px;
  color: var(--accent);
  font-size: 1rem;
  font-family: "SeagoeUI";
  cursor: pointer;
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 12px;
  transition: all 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.quantum-orb {
  position: relative;
  width: var(--orb-size);
  height: var(--orb-size);
  background: var(--accent);
  border-radius: 50%;
  transition: all var(--duration) cubic-bezier(0.22, 1, 0.36, 1);
}

.quantum-button::before {
  content: "";
  position: absolute;
  inset: 0;
  background: var(--accent);
  border-radius: inherit;
  transform: scale(0);
  transform-origin: top;
  transition: transform var(--duration) cubic-bezier(0.22, 1, 0.36, 1);
}

.quantum-button:hover::before {
  transform: scale(1);
}

.quantum-text {
  position: relative;
  z-index: 2;
  transition: color var(--duration) cubic-bezier(0.22, 1, 0.36, 1);
}

.quantum-button:hover {
  border-color: transparent;
  transform: translateY(-2px) scale(1.005);
  box-shadow: 0 10px 40px -10px rgba(248, 248, 248, 0.15),
    0 0 20px rgba(248, 248, 248, 0.1);
}

.quantum-button:hover .quantum-text {
  color: var(--primary);
}

.quantum-button:hover .quantum-orb {
  background: var(--primary);
}

.quantum-button:active {
  transform: translateY(1px) scale(0.995);
  box-shadow: 0 5px 20px -5px rgba(248, 248, 248, 0.1);
}

.calendar-container-dates {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1vw;
  width: 100%;
}

.calendar-card {
  background: white;
  border-radius: 12px;
  padding: 1.75rem;
  position: relative;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  box-sizing: border-box;
}

.calendar-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 71, 87, 0.05)
  );
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 0;
}

.calendar-card:hover {
  transform: translateY(-4px) scale(1.01);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1), 0 6px 6px rgba(0, 0, 0, 0.06);
}

.calendar-card:hover::before {
  opacity: 1;
}

.calendar-card.active-card {
  border: 1px solid rgba(255, 71, 87, 0.4);
  animation: pulse 2s infinite;
  box-shadow: 0 0 0 1px #ff4757, 0 0 20px rgba(255, 71, 87, 0.2);
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 1px rgba(255, 71, 87, 1), 0 0 20px rgba(255, 71, 87, 0.2);
  }
  50% {
    box-shadow: 0 0 0 1px rgba(255, 71, 87, 1), 0 0 25px rgba(255, 71, 87, 0.4);
  }
  100% {
    box-shadow: 0 0 0 1px rgba(255, 71, 87, 1), 0 0 20px rgba(255, 71, 87, 0.2);
  }
}

.calendar-card:active {
  transform: scale(0.98);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.calendar-header {
  margin-bottom: 1rem;
}

.calendar-number {
  background: #f3f4f6;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-family: "SeagoeUIBold";
}

.date-range {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.start-date,
.end-date {
  text-align: center;
  font-family: "SeagoeUIBold";
}

.date {
  font-size: 1.5rem;
  color: #1f2937;
}

.month-year {
  color: #6b7280;
  font-size: 0.875rem;
}

.range-divider {
  padding: 0 1.5rem;
}

.arrow {
  color: #000000;
  font-size: 1.25rem;
}

.calendar-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.calendar-title {
  font-size: 3rem;
  font-family: "SeagoeUIBold";
  color: black;
  margin: 0 auto;
  opacity: 0.3;
}

.calendar-transition-container {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.calendar-slide {
  flex-shrink: 0;
  width: 100%;
  opacity: 0;
  transform: translateX(100px);
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  position: absolute;
  top: 0;
  left: 0;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  will-change: transform, opacity;
}

.calendar-slide.active {
  opacity: 1;
  transform: translateX(0);
  position: relative;
}

.calendar-slide:not(.active):not(:first-child) {
  transform: translateX(-100px);
}

.react-datepicker__current-month {
  font-family: "SeagoeUIBold" !important;
}

.react-datepicker__day {
  font-size: 1em;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-range {
  background: black !important;
  color: #fff !important;
  border-radius: 50%;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-range {
  font-family: "SeagoeUIBold";
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 50% !important;
  font-size: 1em;
}

.react-datepicker__day--in-range:not(.react-datepicker__day--selected):not(
    .react-datepicker__day--keyboard-selected
  ) {
  background-color: rgba(0, 0, 0, 0.15) !important;
  color: black !important;
  border-radius: 50% !important;
  /* mai vezi daca poti sa faci negre doar start si end date restu gri (mergea la add campaign, nu merge la edit, nu o ia enddate) */
}

.react-datepicker__week
  .react-datepicker__day--in-range:not(.react-datepicker__day--selected):not(
    .react-datepicker__day--keyboard-selected
  ) {
  background: black !important;
  color: white !important;
  border-radius: 50% !important;
  font-family: "SeagoeUIBold" !important;
}

.react-datepicker__day--disabled {
  color: #ccc !important;
  pointer-events: none;
}

.react-datepicker__day--in-selecting-range:not(
    .react-datepicker__day--selected
  ) {
  background-color: black !important;
}

.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
  font-family: "SeagoeUIBold";
  font-weight: initial !important;
}

.remove-calendar-btn {
  align-items: center;
  display: flex;
  justify-content: center;
  background: #ff4757;
  border: none;
  border-radius: 50%;
  padding: 12px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  transition: all 0.3s ease;
  color: white;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 71, 87, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(255, 71, 87, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 71, 87, 0);
  }
}

.remove-calendar-btn:hover {
  background: white;
  color: #ff4757;
  transform: rotate(180deg);
  box-shadow: 0 6px 20px rgba(255, 71, 87, 0.4);
}

.chevron-btn {
  align-items: center;
  display: flex;
  justify-content: center;
  background: black;
  border: none;
  border-radius: 50%;
  padding: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: white;
  animation: pulse 2s infinite;
}

.chevron-btn:hover {
  background: white;
  color: #ff4757;
  transform: scale(1.1);
  box-shadow: 0 6px 20px rgba(255, 71, 87, 0.4);
}

.chevron-btn:disabled {
  background: #ffeded;
  cursor: not-allowed;
  box-shadow: none;
  color: #ffb3b3;
  animation: none;
}

.chevron-container {
  display: flex;
  column-gap: 10px;
  align-items: center;
}
